<template>
  <base-layout>
    <IotCapabilitiesAddEdit />
    <content-section :spacing="false">
      <div class="content-spacing">
        <title-plus title="IoT Capabilities" :hidePlus="true" class="mb-4" />
      </div>
      <div
        class="
          flex flex-col
          mt-0
          mb-6
          px-4
          space-y-4
          md:space-y-0 md:items-center md:flex-row
        "
      >
        <card-tax-stat
          title="Total IoT Types"
          :value="indexMetaData.count.total"
          variant="green"
          class="mr-3"
        />
      </div>
      <fsTableContainer class="px-2 vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <a class="text-blue-600" href="#" @click.prevent="edit(item)">{{
                item.id | onlyLastFive
              }}</a>
            </fsTableRowItem>

            <fsTableRowItem>{{
              item.iot_category.replaceAll('_', ' ')
            }}</fsTableRowItem>

            <fsTableRowItem>{{ item.port ? item.port : 'N/A' }}</fsTableRowItem>

            <fsTableRowItem>
              {{
                getFormattedDateTime(item.created_at, 'D MMM YYYY, h:mm:ss a')
              }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{
                getFormattedDateTime(item.updated_at, 'D MMM YYYY, h:mm:ss a')
              }}
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>

        <!-- fs-table-footer -->
        <!-- todo: change the hardcoded total-items & add paginationSummary -->
        <fs-table-foot :total-items="20" />
        <!-- fs-table-footer -->
      </fsTableContainer>
    </content-section>
  </base-layout>
</template>

<script>
import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'

// import roleApi from '@/config/api/role'

// Components
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
// import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
// import OtoSearch from '@/components/ui/OtoSearch'
import CardTaxStat from '@/components/cards/TaxStat'

import IotCapabilitiesAddEdit from './IotCapabilitiesAddEdit'
// import MoreActionsDropdown from "@/components/dropdown/MoreActionsDropdown.vue";
import { getFormattedDateTime } from '@/utils/datetime'
import { EventBus } from '@/utils/EventBus'
import { IotCapabilitiesConfig } from '@/config/IotCapabilitiesConfig'

export default {
  name: 'IotCapabilitiesIndex',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    fsTableUiMixin,
    getIndexDataMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    IotCapabilitiesAddEdit,
    OtoEditIcon,
    CardTaxStat,
    // MoreActionsDropdown,
  },
  data() {
    return {
      indexDataEndpoint: IotCapabilitiesConfig.api.index,
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      tableHeaders: [
        {
          name: 'ID',
          width: '15%',
          field: 'id',
          sort: false,
          order: '',
        },
        {
          name: 'IoT Type',
          width: '15%',
          field: 'iot_category',
          sort: false,
          order: '',
        },
        {
          name: 'Port',
          width: '10%',
          field: 'port',
          sort: false,
          order: '',
        },
        {
          name: 'Created At',
          width: '20%',
          field: 'created_at',
          sort: null,
          order: '',
        },
        {
          name: 'Updated At',
          width: '20%',
          field: 'updated_at',
          sort: null,
          order: '',
        },
        {
          name: 'Actions',
          width: '20%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  mounted() {
    window.addEventListener(IotCapabilitiesConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    getFormattedDateTime,
    handleDeletion: function(e) {
      console.log({ delete: e })
    },
    add() {
      EventBus.$emit(IotCapabilitiesConfig.events.editingData, {})
      dispatchEvent(new Event(IotCapabilitiesConfig.events.sorToggle))
    },
    edit(item) {
      console.log(item)
      EventBus.$emit(IotCapabilitiesConfig.events.editingData, item)
      dispatchEvent(new Event(IotCapabilitiesConfig.events.sorToggle))
    },
  },
  // todo: refresh (re-fetch) data after listening event
  // todo: show toast
}
</script>
